<template>
  <div class="not-found py-5 px-4 text-center">
    <div class="header">
      <img src="@/assets/img/zubut-logo-blue.png" alt="Zubut" width="140" />
    </div>

    <div class="content-wrapper">
      <div class="img-404-wrapper mt-5">
        <img
          id="img-404"
          src="@/assets/img/404.png"
          alt="La página que buscas no existe"
          width="600"
        />
      </div>

      <h2 class="text-2xl font-weight-bold mt-3">
        La página que buscas no existe
      </h2>

      <z-button
        class="mt-3 font-weight-bold"
        variant="primary-alt"
        @click="$router.push('/')"
      >
        Volver al incio
      </z-button>
    </div>

    <div
      class="mt-4 w-100 d-md-flex justify-content-center text-center align-items-center"
    >
      <div class="font-weight-bold mr-md-5 text-dark">
        Visita

        <a href="http://zubut.com/" class="text-dark">Zubut.com</a>
      </div>

      <div class="mt-3 mt-md-0">
        Illustration by

        <a href="https://icons8.com/illustrations/author/627444">
          Julia Gnedin
        </a>

        from <a href="https://icons8.com/illustrations">Ouch!</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style lang="scss" scoped>
.not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  height: 34px;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#img-404 {
  max-width: 90%;
  max-height: 100%;
}
</style>
